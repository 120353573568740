<template>
  <Page>
    <template #title>
      Orders
    </template>

    <Table
      date-key="completedAt"
      :items="orders"
      :total-item-count="orderCount"
      :count="{ singular: 'order', plural: 'orders' }"
      :state="state"
      :items-per-page="pageSize"
      item-type="Order"
      :headers="tableHeaders"
      hideHeader
      table-id="orders-table"
      @next="goToNextPage"
      @prev="goToPreviousPage"
    >
      <template #tableHeader>
        <div>
          <div class="flex flex-wrap items-end gap-2">
            <div class="w-full max-w-xs">
              <TbInput
                v-model="search"
                label="Search"
                placeholder="Customer / OrderID / Product"
              />
            </div>

            <TbDateRangePicker
              :dates="dates"
              @selected-date-range="setDateRange"
            />
            <div class="flex justify-between flex-grow">
              <OrderExportCsvButtonGroup
                @download="exportCsv"
              />
              <Filter
                class="self-center"
                :showFilters="showFilter"
                :toggleFilter="toggleFilters"
                :cancelFilters="cancelFilters"
                :applyFilters="applyFilters"
                :filters="projectFeatures.ppmEnabled ? {
                  status: {
                    value: 'status',
                    label: 'By Status',
                    options: statusFilters,
                  },
                  type: {
                    value: 'type',
                    label: 'By Type',
                    options: typeFilters,
                  },
                } : {
                  status: {
                    value: 'status',
                    label: 'By Status',
                    options: statusFilters,
                  },
                }"
              />
            </div>
          </div>
        </div>
        <div
          v-if="selectedStatus.length > 0 || selectedType.length > 0"
          class="flex flex-wrap gap-2 mt-4"
        >
          <button
            v-for="status in selectedStatus"
            :key="status.value"
            class="flex items-center border button button--sm border-primary"
            @click="removeStatusFilters(status)"
          >
            <TbXMarkIcon class="w-4 h-4 mr-2" />
            <span>{{ status.label }}</span>
          </button>
          <button
            v-for="type in selectedType"
            :key="type.value"
            class="flex items-center border button button--sm border-primary"
            @click="removeTypeFilters(type)"
          >
            <TbXMarkIcon class="w-4 h-4 mr-2" />
            <span>{{ type.label }}</span>
          </button>
        </div>
      </template>

      <template #action="{ item, toggleDropdown }">
        <div class="flex flex-col h-full gap-1">
          <RefundForm
            :is-refundable="item.refundable"
            :order="item"
            :refund-enabled="projectConfigs.refundEnabled"
            @refund="loadOrders()"
          />

          <CancelOrderForm
            :is-cancellable="item.cancellable"
            :order="item"
            @cancel-order="loadOrders()"
          />

          <button
            class="button button--block button--sm text-left flex gap-2 items-center"
            @click="toggleDropdown(); copyOrderId(item)"
          >
            Copy Order ID
            <TbCopyIcon class="w-4 h-4" />
          </button>
        </div>
      </template>

      <template #row(formattedOrigin)="{ value }">
        <OriginChip :title="value" />
      </template>

      <template #row(product)="{ value }">
        <div class="max-w-[150px] break-words whitespace-normal">
          {{ value }}
        </div>
      </template>

      <template #row(studentName)="{ item }">
        <routerLink :to="{ name: Pages.dashboardStudent, params: { id: item.studentId }}">
          <div class="flex flex-col">
            <span>{{ item.studentName }}</span>
            <span class="text-xs text-gray-500">
              {{ item.studentEmail }}
            </span>
          </div>
        </routerLink>
      </template>

      <template #row(installmentsCount)="{ value, item }">
        <div class="flex flex-col">
          <span>{{ value > 1 ? `${value} Months` : `${value} Month` }}</span>
          <span
            v-if="item.origin === 'ppm'"
            class="text-xs text-gray-500"
          >{{ item.paidInstallments }}/{{ value }} Paid</span>
        </div>
      </template>

      <template #row(amountInCents)="{ item }">
        <div class="flex flex-col">
          <span class="currency">{{ item.currency + currencyInCents(item.amountInCents) }}</span>
          <span
            v-if="item.origin === 'ppm'"
            class="text-xs text-gray-500"
          >{{ `${currencyInCents(item.paidAmountInCents)} Received` }}</span>
        </div>
      </template>

      <template #row(status)="{ value, item }">
        <div>
          <div>
            <span
              class="inline-block w-2 h-2 mr-1 rounded-full"
              :class="orderStatusToColorMap[value]"
            />
            <span>{{ value }}</span>
          </div>
          <TbChip
            v-if="item.reverseCandidate === true"
            text="Reversal Candidate"
            small="true"
            color="gray-300"
            textColor="black"
            class="!px-2 !py-0 inline-block my-1"
          />
        </div>
      </template>
    </Table>
  </Page>
  <QuickStartGuideLink />

  <TbOverlay
    :show="csvState === PageState.loading"
    persistent
  >
    <div class="flex items-center justify-center w-full h-full">
      <TbLoading />
    </div>
  </TbOverlay>

  <TbNotification
    :show="copyNotification"
    title="Success 🎉"
    :message="copyNotificationText"
    card-class="text-white bg-success"
    @close="copyNotification = false"
  />
</template>

<script setup lang="ts">
import {
  ref, Ref, onActivated, watch, inject,
} from 'vue';
import {
  TbInput, TbDateRangePicker, TbLoading, TbOverlay, TbXMarkIcon, TbNotification, TbCopyIcon, TbChip,
} from '@/components/tasty_bistro';
import { subMonths } from 'date-fns';
import { debounce } from 'lodash';
import { useRoute } from 'vue-router';
import { useClipboard } from '@vueuse/core';
import { Pages } from '@/router';
import { PageState } from '@/types';
import Table from '@/components/table.vue';
import Filter, { FilterOption } from '@/components/filter.vue';
import { orderStatusToColorMap } from '@/helpers/category_color_mapper';
import OriginChip from '@/components/origin_chip.vue';
import { formatDate } from '@/filters/date';
import { currencyInCents } from '@/filters/currency';
import { downloadFile } from '@/helpers/download_file';
import QuickStartGuideLink from '@/components/quick_start_guide_link.vue';
import { AppState } from '@/pages/app/api/get_app_state';
import { Order, getOrders } from './api/get_orders';
import { getOrdersDownload } from './api/get_orders_download';
import Page from '../components/page.vue';
import RefundForm from './forms/refund.vue';
import CancelOrderForm from './forms/cancel_order.vue';
import OrderExportCsvButtonGroup from './components/order_export_csv_button_group.vue';

const orders = ref<Order[]>();
const search = ref('');
const csvState = ref(PageState.loaded);
const state: Ref<PageState> = ref(PageState.loading);
const showFilter = ref(false);
const copyNotification = ref(false);
const copyNotificationText = ref('');
const showDropdown = ref(false);

type Dates = [Date, Date]
const dates = ref<Dates>([subMonths(new Date(), 6), new Date()]);
const pageSize = 20;
const currentPage = ref(0);
const orderCount = ref<number>(0);
const statusFilters = ref<FilterOption[]>([]);
const selectedStatus = ref<FilterOption[]>([]);
const selectedType = ref<FilterOption[]>([]);

const typeFilters = [
  { label: 'Funding', checked: false, value: 'lnpl' },
  { label: 'In-House', checked: false, value: 'ppm' },
];

const tableHeaders = [
  {
    title: 'Date',
    key: 'completedAt',
    formatter: (val: string) => formatDate(val, 'MMM dd, yyyy'),
  },
  {
    title: 'Customer',
    key: 'studentName',
  },
  {
    title: 'Checkout',
    key: 'product',
  },
  {
    title: 'Type',
    key: 'formattedOrigin',
  },
  {
    title: 'Term',
    key: 'installmentsCount',
    formatter: (val: string) => `${val} Months`,
  },
  {
    title: 'Total',
    key: 'amountInCents',
    formatter: currencyInCents,
    class: 'currency',
  },
  {
    title: 'Status',
    key: 'status',
  },
];

const appState = inject<Ref<AppState>>('state') as Ref<AppState>;
const route = useRoute();
const projectId = route.params.projectId as string;

const projectConfigs = appState.value.projects[projectId].configs;
const projectFeatures = appState.value.projects[projectId].features;

const loadOrders = async () => {
  try {
    state.value = PageState.loading;
    const data = await getOrders({
      projectId,
      filter: {
        startDate: dates.value[0],
        endDate: dates.value[1],
        pageSize,
        offset: currentPage.value * pageSize,
        search: search.value,
        status: selectedStatus.value.map((s) => s.value),
        origin: selectedType.value.map((t) => t.value),
      },
    });

    orders.value = data.orders;
    orderCount.value = Number(data.totalCount);
    statusFilters.value = data.statusFilters.map(
      (sf) => ({
        ...sf,
        checked: selectedStatus.value
          .map((ss) => ss.value).includes(sf.value),
      }),
    );
    state.value = PageState.loaded;
  } catch (error) {
    state.value = PageState.error;
  }
};

const exportCsv = async (format: 'order_tax_report' | 'orders') => {
  try {
    csvState.value = PageState.loading;
    const file = await getOrdersDownload({
      format,
      projectId,
      filter: {
        startDate: dates.value[0],
        endDate: dates.value[1],
        search: search.value,
        status: selectedStatus.value.map((s) => s.value),
        origin: selectedType.value.map((t) => t.value),
      },
    });
    downloadFile({ file, fileName: `elective_${format}.csv` });
    csvState.value = PageState.loaded;
  } catch (error) {
    csvState.value = PageState.error;
  }
  if (showDropdown.value) {
    showDropdown.value = false;
  }
};

const copyOrderId = (order: Order) => {
  copyNotification.value = false;

  setTimeout(() => {
    const clipboard = useClipboard({ source: order.id });
    clipboard.copy();
    copyNotificationText.value = 'Order ID has been successfully copied to your Clipboard';
    copyNotification.value = true;
  }, 200);
};

onActivated(async () => {
  await loadOrders();
});

const setDateRange = async (newDates: Dates) => {
  currentPage.value = 0;
  dates.value = newDates;
  await loadOrders();
};

watch(search, debounce(async () => {
  currentPage.value = 0;
  await loadOrders();
}, 500));

const goToNextPage = async (page: number) => {
  currentPage.value = page;
  await loadOrders();
};

const goToPreviousPage = async (page: number) => {
  currentPage.value = page;
  await loadOrders();
};

const toggleFilters = () => {
  showFilter.value = !showFilter.value;
};

const applyFilters = async () => {
  showFilter.value = false;
  selectedStatus.value = statusFilters.value?.filter((s) => s.checked) || [];
  selectedType.value = typeFilters.filter((t) => t.checked);
  await loadOrders();
};

const removeStatusFilters = async (status: FilterOption) => {
  selectedStatus.value = selectedStatus.value.filter((ss) => ss.value !== status.value);
  statusFilters.value
    .forEach((sf) => {
      if (sf.value === status.value) {
        sf.checked = false;
      }
    });

  await loadOrders();
};

const removeTypeFilters = async (type: FilterOption) => {
  selectedType.value = selectedType.value.filter((st) => st.value !== type.value);
  typeFilters
    .forEach((sf) => {
      if (sf.value === type.value) {
        sf.checked = false;
      }
    });
  await loadOrders();
};

const cancelFilters = () => {
  showFilter.value = false;
};
</script>

<style lang="scss">
  .table {
    @apply bg-secondary-100 w-full
  }

  .student-more-btn  {
    @apply py-0 px-2 ml-2
  }

  #orders-table tbody tr {
    vertical-align: top;
    text-align: start;
  }

  .custom-disabled {
    @apply cursor-not-allowed opacity-[0.35];
  }
</style>
