<template>
  <RoleControlledAction
    v-slot="{wrapper, restricted}"
    :user-role="roleName"
    :config-object="roleConfig.exportOrders"
  >
    <button
      v-if="!hasCheckoutSalesTaxEnabled"
      :class="{ 'custom-disabled': restricted}"
      class="mt-2 button button--primary button--sm lg:mt-0"
      @click="wrapper(()=> emit('download', 'orders'))"
    >
      <span
        class="flex items-center gap-2"
      >
        Export to CSV
        <TbDownloadIcon class="w-4 h-4 text-white" />
      </span>
    </button>
    <Dropdown
      v-else
      @toggle="handleToggle"
    >
      <template #toggle="{ toggleDropdown }">
        <button
          :class="{ 'custom-disabled': restricted}"
          class="mt-2 button button--primary button--sm lg:mt-0"
          @click="toggleDropdown"
        >
          <span
            class="flex items-center gap-2"
          >
            Export to CSV
            <TbDownloadIcon class="w-4 h-4 text-white" />
            <TbIcon
              v-if="isChevronUp"
              icon="chevron-up"
            />
            <TbIcon
              v-else
              icon="chevron-down"
            />
          </span>
        </button>
      </template>

      <template #default="{ toggleDropdown }">
        <div>
          <div
            v-for="key in csvOptions"
            :key="key.value"
          >
            <button
              class="button button--block button--sm text-left flex gap-2 items-center"
              @click="wrapper(()=> handleCsvButton(key.value, toggleDropdown))"
            >
              <span class="font-bold">
                {{ key.label }}
              </span>
            </button>
          </div>
        </div>
      </template>
    </Dropdown>
  </RoleControlledAction>
</template>

<script setup lang="ts">
import {
  Ref, inject, defineEmits, ref,
} from 'vue';
import { TbDownloadIcon, TbIcon } from '@/components/tasty_bistro';
import { useRoute } from 'vue-router';
import { AppState } from '@/pages/app/api/get_app_state';
import RoleControlledAction from '@/components/role_controlled_action.vue';
import Dropdown from '@/components/dropdown.vue';
import { roleConfig } from '../role_configurations';

const appState = inject<Ref<AppState>>('state') as Ref<AppState>;
const route = useRoute();
const projectId = route.params.projectId as string;

const projectFeatures = appState.value.projects[projectId].features;
const roleName = appState.value.projects[projectId].currentUserRoleName;

const hasCheckoutSalesTaxEnabled = projectFeatures?.checkoutSalesTaxEnabled;

const emit = defineEmits(['download']);
const isChevronUp = ref(false);

const handleToggle = (toggle: boolean) => {
  isChevronUp.value = toggle;
};
const csvOptions = [
  {
    label: 'Order Details',
    value: 'orders',
  },
  {
    label: 'Order Tax Report',
    value: 'order_tax_report',
  }];

const handleCsvButton = async (event: string, toggleDropdown: () => void) => {
  emit('download', event);
  toggleDropdown();
};
</script>
