import http, { Http } from '@/lib/http';

export interface FilterOptions {
  student?: string;
  product?: string;
  pageSize?: number;
  offset?: number;
  startDate?: Date;
  endDate?: Date;
  search?: string;
  status: string[] | undefined;
  origin: string[] | undefined;
}

interface Params {
  projectId: string;
  filter: FilterOptions;
  format?: 'order_tax_report' | 'orders';
}

function getOrdersDownloadCtor(http: Http) {
  return async ({ filter, projectId, format = 'orders' }: Params) => {
    const { data } = await http.get(`/projects/${projectId}/orders/download/${format}`, {
      responseType: 'arraybuffer',
      params: filter,
    });

    return new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
  };
}

export const getOrdersDownload = getOrdersDownloadCtor(http);
