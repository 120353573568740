<template>
  <Page>
    <div
      v-if="hasPpmEnabled"
      class="custom-card card mb-4"
    >
      <div class="text-primary font-semibold">
        Payment Gateways
      </div>
      <div class="text-sm sm:max-w-[80%]">
        Easily let your customers pay in full or pay over time with your own payment plans, fully managed by Elective.
      </div>
      <div
        v-for="{ name, logoSrc, logoCss, buttonComponent, integrations, hasActiveIntegration, hidden } in availablePsp"
        :key="name"
        class="flex flex-col mt-6"
      >
        <div
          v-if="!hidden"
          class="w-full flex justify-between"
        >
          <div class="text-sm font-semibold text-primary">
            <img
              :src="logoSrc"
              :alt="`${name} Logo`"
              :class="logoCss"
              class="self-start mb-2"
            >
            {{ name }}
          </div>

          <component
            :is="buttonComponent"
            v-if="buttonComponent && !hasActiveIntegration"
            :projectId="projectId"
          />

          <div
            v-if="!buttonComponent && !hasActiveIntegration"
            class="self-end"
          >
            <button
              class="text-center button button--primary button--sm"
              @click="router.push({name: `${name.toLowerCase()}Form`})"
            >
              Add Account
            </button>
          </div>
        </div>
        <CredentialsTable
          v-if="integrations && integrations.length"
          :integrations="integrations"
          @archive="loadIntegrations()"
        />
      </div>
    </div>

    <TaxGroup
      :integrations="taxIntegrations"
      @archive="loadIntegrations()"
    />

    <div class="custom-card card">
      <div class="text-primary font-semibold">
        Workflow Automation
      </div>
      <div class="text-sm sm:max-w-[80%]">
        Build out seamless automations by connecting Elective to hundreds of different applications via Zapier.
      </div>
      <div class="flex justify-between items-center mt-4">
        <div class="text-sm font-semibold text-primary">
          <img
            src="./assets/zapier.svg"
            alt="Zapier Logo"
            class="h-[33px] self-start mb-2"
          >
          Zapier
        </div>
        <div class="self-end">
          <button
            class="text-center button button--primary button--sm"
            @click="router.push({name: 'zapierDashboard'})"
          >
            Connect
          </button>
        </div>
      </div>
    </div>
  </Page>
</template>

<script setup lang="ts">
import {
  ref,
  onBeforeMount,
  inject,
  Ref,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Page from '@/pages/app/project/dashboard/components/page.vue';
import { AppState } from '@/pages/app/api/get_app_state';
import stripeLogo from './assets/stripe.svg';
import nmiLogo from './assets/nmi.svg';
import StripeButton from './stripe/index.vue';
import CredentialsTable from './components/credentials_table.vue';
import { getIntegrations, Integration } from './api';
import TaxGroup from './sales_tax/index.vue';

interface AvailablePsp {
  name: string;
  logoSrc: string;
  logoCss: string;
  buttonComponent?: any;
  integrations?: Integration[];
  hasActiveIntegration?: boolean;
  hidden: boolean;
}
const route = useRoute();
const router = useRouter();
const appState = inject<Ref<AppState>>('state') as Ref<AppState>;
const projectId = ref<string>(route.params.projectId as string);
const hasPpmEnabled = appState.value.projects[projectId.value].features?.ppmEnabled;
const existingIntegrations = ref<Integration[]>([]);
const paymentIntegrations = ref<Integration[]>([]);
const taxIntegrations = ref<Integration[]>([]);

function getPspIntegration(name: string): Integration[] {
  return paymentIntegrations.value.filter((integration) => integration.integrationSource.toLowerCase() === name.toLowerCase());
}

const availablePsp = ref<AvailablePsp[]>([
  {
    name: 'Stripe',
    logoSrc: stripeLogo,
    logoCss: 'w-[74px] h-[31.34px]',
    buttonComponent: StripeButton,
    hidden: false,
  },
  {
    name: 'NMI',
    logoSrc: nmiLogo,
    logoCss: 'w-[74.13px] h-[23.88px]',
    hidden: false,
  },
]);

async function loadIntegrations() {
  existingIntegrations.value = await getIntegrations(projectId.value);
  taxIntegrations.value = existingIntegrations.value.filter((integration) => integration.integrationType === 'sales_tax');
  if (!hasPpmEnabled) return;
  paymentIntegrations.value = existingIntegrations.value.filter((integration) => integration.integrationType === 'payments');
  availablePsp.value.forEach((psp) => {
    psp.integrations = getPspIntegration(psp.name);
    psp.hasActiveIntegration = psp.integrations?.filter((i) => i.state !== 'unauthorized').length > 0;
  });
}

onBeforeMount(async () => {
  await loadIntegrations();
});

</script>

<style scoped lang="scss">
.custom-card {
  @apply p-4 bg-white max-w-lg;
}

</style>
